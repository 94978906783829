/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'

import domready from 'domready'
import mobileNavigation from './modules/mobile-navigation'
import initToggleSubMenu from './modules/toggle-sub-menu'
import Swiper from 'swiper'
import baguetteBox from 'baguettebox.js'
import initMaps from './modules/map'
import formSubmit from './modules/form-submit'
/**
 * Ab geht die Reise
 */
domready(() => {
  initToggleSubMenu()
  initMaps()
  formSubmit()
  baguetteBox.run('.lightbox')
  mobileNavigation({
    activationElement: '.mobile-hamburger',
    breakpoint: 768
  })
  // eslint-disable-next-line
  new Swiper('.swiper-container', {
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 1000,
    loop: true
  })
  window.onscroll = function () {
    scrollFunction()
  }

  function scrollFunction () {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById('myBtn').style.display = 'block'
    } else {
      document.getElementById('myBtn').style.display = 'none'
    }
  }

  function scrollToTop (scrollDuration) {
    const scrollHeight = window.scrollY
    const scrollStep = Math.PI / (scrollDuration / 15)
    const cosParameter = scrollHeight / 2
    let scrollCount = 0
    let scrollMargin
    let scrollTop = 0
    let scrollInterval = setInterval(function () {
      if ('pageXOffset' in window) {	// all browsers, except IE before version 9
        scrollTop = window.pageYOffset
      } else {		// Internet Explorer before version 9
        scrollTop = document.documentElement.scrollTop
      }
      if (scrollTop !== 0) {
        scrollCount = scrollCount + 1
        scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep)
        window.scrollTo(0, (scrollHeight - scrollMargin))
      } else {
        clearInterval(scrollInterval)
      }
    }, 15)
  }

  document.getElementById('myBtn').addEventListener('click', function () {
    scrollToTop(1000)
  })
})
